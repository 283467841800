@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans&display=swap");

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

body {
   font-family: "Inter", sans-serif;
}

.full {
   height: 100% !important;
   width: 100% !important;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.ant-card-body {
   padding: 5px !important;
}
.tracking-card {
   line-height: 12px !important;
}
svg {
   vertical-align: baseline;
}

/* thead[class*="ant-table-thead"] th {
   background-color: #8db1f4 !important;
} */

.chat-side {
   background-color: #f9f9f9;
   width: 250px;
}

.conversation-item:hover {
   background-color: white;
   border-color: #2c6cf4;
   border-width: 2px;
}

.conversation-item-selected {
   background-color: white !important;
   border-color: #2c6cf4 !important;
   border-width: 2px !important;
}

.conversation-item {
   background-color: #f9f9f9;
   cursor: pointer !important;
   border-radius: 10px;
   margin: 5px;
   border-color: #f9f9f9;
   border-width: 2px;
   box-sizing: border-box;
}

.chat-message {
   max-width: 48%;
   background-color: #f3f3f3;
}

.send-button:hover {
   opacity: 0.6;
}

.send-input-border {
   background-color: #a4a4a4;
   height: 1px;
}

.timeline {
   background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}

.text-vertical {
   writing-mode: vertical-lr;
}

.no-scroll-container {
   -ms-overflow-style: none; /* Internet Explorer 10+ */
   scrollbar-width: none; /* Firefox */
}
.no-scroll-container::-webkit-scrollbar {
   display: none; /* Safari and Chrome */
}

.ride-details-map {
   min-height: 300px !important;
}

.ant-modal-content {
   overflow: hidden !important;
}

.level-1-bg {
   background: linear-gradient(90deg, #ffeec4 0%, #fff9e9 100%);
}
.level-2-bg {
   background: linear-gradient(90deg, #ffddd0 0%, #ffe8df 100%);
}
.level-3-bg {
   background: linear-gradient(90deg, #d2e5ff 0%, #e9f2ff 100%);
}

.level-1-solid-color {
   color: #f5ba40;
}
.level-2-solid-color {
   color: #448ef7;
}
.level-3-solid-color {
   color: #db673a;
}

.select-driver-model {
   width: 900px !important;
   max-width: 900px !important;
}
